@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .floating-action-button {
    @apply flex justify-center items-center w-14 h-14 wiki-card p-2 bg-base-100 rounded-full hover:outline-dashed outline-offset-2 outline-1 hover:scale-110 duration-300;
  }
  .wiki-icon {
    @apply w-7 h-7;
  }
  .wiki-card {
    @apply bg-base-100 shadow-2xl shadow-slate-400;
  }
  .wiki-menu {
    @apply flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75;
  }
}
