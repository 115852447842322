@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .title-section {
    @apply flex justify-between items-center mb-5 font-semibold text-gray-500;
  }
  .popular-card {
    @apply rounded-2xl shadow-xl overflow-hidden;
  }
  .button-carousel {
    @apply p-2 bg-white shadow-md rounded-xl mx-1;
  }
  .circle-act {
    @apply w-10 h-10 object-cover rounded-full;
  }
  .circle-clip {
    @apply w-14 h-14 object-cover rounded-full shadow-xl;
  }
  .circle-icon {
    @apply w-8 h-8 mt-2 bg-gray-100 rounded-full mr-1 shadow-md flex justify-center items-center text-xl;
  }
  .container-music {
    @apply py-4 px-4 sm:px-3 md:px-4 lg:px-6;
  }
  .button {
    @apply bg-gradient-to-r from-primary to-nav2 px-4 py-2 rounded-full shadow-xl text-hero;
  }
}

.icon-path path {
  stroke: currentColor;
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  /* background: #f7f4ed; */
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #2a3942;
  border-radius: 100vh;
  /* border: 3px solid #f6f7ed; */
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: gray;
}
