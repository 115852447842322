@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .circle-nav{
        @apply w-8 h-8 rounded-full mt-3 drop-shadow-2xl shadow-sky-100 flex justify-center items-center;
    }
    .circle-icon{
        @apply w-8 h-8 mt-2 bg-gray-100 rounded-full mr-1 shadow-md flex justify-center items-center text-xl;
    }
    .container{
        @apply max-w-none md:px-10 lg:px-20 py-8 
    }
    .button{
        @apply bg-gradient-to-r from-primary to-nav2 px-4 py-2 rounded-full shadow-xl text-hero
    }
    .admin-card{
        @apply shadow-xl rounded-xl bg-white p-3
    }
    .admin-card-small{
        @apply flex justify-center items-center gap-2 text-teal-800 bg-opacity-5 lg:h-20 md:h-12
    }
    
    .react-calendar{
        @apply !border-none !font-body 
    }

    .paragraph{
        @apply text-justify leading-relaxed indent-8
    }

    .transition{
        @apply transition-all delay-150
    }

}



.react-calendar__month-view__days{
    /* gap: 5px !important; */
}

.react-calendar__tile--now, .react-calendar__tile {    
    border-radius: 50%;        
    height: 50px;
}

html{
    height: -webkit-fill-available;
}
body{
    height: -webkit-fill-available;
}

.nyusss-h {
    height: 100svh;
}